

.signup-page {
    @apply grid gap-2 lg:gap-4 md:flex mt-12 mb-12 sm:mb-24;
}

.signup-left {
    @apply w-full md:w-2/6 pb-4;
}

.signup-right {
    // padding: 0 10%;
    @apply px-4 md:px-12 py-8 mx-4 w-auto md:w-3/5 lg:w-4/6 2xl:w-3/6 border rounded-md sm:py-20;
}

.steps {
    @apply w-[calc(100%-32px)] mx-4 mb-4 p-4 border border-gray-700 rounded-md hover:border-gray-300 hover:bg-gray-50;
}

.steps h2 {
    @apply font-semibold;
}

.steps p {
    @apply text-xs font-light ml-8;
}