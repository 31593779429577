

// Header 

.header-container {
    // background-image: url(https://c1.wallpaperflare.com/preview/464/689/234/duel-footballers-opponents-football.jpg);
    background-image: url(https://img.freepik.com/premium-photo/kids-soccer-stadium-mixed-media_641298-22404.jpg);
    // background-size: 100%;
    // background-repeat: no-repeat;
    @apply -mt-16 pt-16 bg-cover;
}

.header-shade {
    @apply w-full h-full bg-lightBlue-950/90 -mt-16 pt-20 pb-40;
}

.header-text {
    @apply w-full sm:w-1/2 px-8 sm:px-4 py-24;
}

.header-text h2 {
    @apply text-white text-5xl font-bold uppercase tracking-tight;
}

.header-text h6 {
    @apply text-lg text-gray-400;
}




// Promo

.content-1 {
    @apply grid sm:flex pt-12 pb-16 gap-12
    sm:px-4 lg:px-44
    ;
    // @apply px-44 grid grid-cols-2 gap-6 sm:grid-cols-1 md:grid-cols-2;
}

.promo {
    @apply w-full pr-16 sm:pr-0 sm:w-1/3;
}

.promo-div1 {
    @apply w-1/3 bg-white rounded-md p-6 ml-10 z-20 relative shadow-md -mt-52;
}

.promo-div1 p {
    @apply text-red-500 text-center text-xl uppercase leading-5 font-bold;
}

.promo-div2 {
    @apply w-3/4 -mt-12 px-4 py-10 bg-red-500 rounded-lg float-right z-10;
}

.promo-div2 h2 {
    letter-spacing: -10px;
    @apply text-white text-center text-8xl font-bold;
}

.promo-div2 h4 {
    @apply text-white text-center text-xl leading-5 font-bold;
}

.promo-div2 p {
    @apply text-white text-center text-xl uppercase leading-5 font-light tracking-widest py-4;
}




// Showcase

.showcase {
    @apply w-full px-4 sm:px-0 sm:w-2/3;
}

.video-container {
    @apply sm:-mt-72;
}

.video-title {
    @apply bg-white p-6 rounded-b-md uppercase shadow-sm;
}

.video-title p, .news-title p {
    @apply text-gray-500 text-xs;
}

.video-title h2, .news-title h2 {
    @apply py-1 text-gray-700 font-bold;
}




// content 2

.content-2, .content-3 {
    @apply px-4 py-12;
}

.content-title {
    @apply text-2xl font-bold tracking-tight uppercase border-l-4 border-l-purple-500 px-2 py-2 mb-8;
}

.programs-container {
    @apply grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-4;
}

.program {
    @apply text-center;
}

.program h2 {
    @apply text-sm uppercase tracking-wide border-b mb-2 pb-1;
}

.program p {
    @apply text-gray-500 text-xs;
}

.program-icon {
    @apply m-auto text-gray-700 mb-4 mt-4;
}





// Content-3 

.content-3 {
    @apply grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 2xl:grid-cols-3;
}

.c3-dark {
    @apply bg-gray-800/95 rounded-md p-6;
}

.c3-dark h2 {
    @apply text-white uppercase leading-5 font-bold;
}

.c3-dark h4 {
    @apply text-gray-400/80 text-xs pt-1.5;
}

.c3-dark p {
    @apply w-5/6 text-gray-300/80 text-sm pb-1;
}

.c3-dark span {
    @apply text-purple-200;
}

.news-title {
    @apply bg-white rounded-b-md uppercase shadow-lg;
}

.c3-light {
    @apply rounded-md;
}

.author{
    @apply float-right text-gray-500 text-xs;
}




// Sponsors

.site-sponsors {
    @apply grid grid-cols-6 gap-6 sm:grid-cols-6 md:grid-cols-12 place-items-center;
    // @apply flex place-items-center;
}

.site-sponsors img {
    @apply rounded-md;
    // @apply w-16 rounded-md m-4;
}

.footer-joined {
    // @apply;
    background-image: url(https://img.freepik.com/premium-photo/kids-soccer-stadium-mixed-media_641298-22404.jpg);
    background-size: 100%;
}